<template>
  <div class="workform">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form ref="formRef" :inline="true" class="form-inline">
      <el-row>
        <el-col :span="24">
          <el-form-item label="公司:" prop="companyID" class="no-print">
            <el-tree-select ref="companys" v-model="forms.companyID" :data="companys" :disabled="!startNode" clearable
              @change="forms.departmentID = ''; companyChange()" check-strictly :render-after-expand="false"
              style="min-width:180px" />
          </el-form-item>
          <el-form-item label="部门:" prop="departmentID">
            <el-tree-select ref="departments" v-model="forms.departmentID" :data="departments"
              :disabled="!startNode" check-strictly :render-after-expand="false" style="min-width:120px" />
          </el-form-item>
          <el-form-item label="渠道:" prop="platformName">
            <el-select v-model="forms.platformName" style="min-width:110px" :disabled="!startNode" filterable :multiple-limit="1">
              <el-option v-for="dd in dict.platforms" :key="dd.value" :label="dd.label" :value="dd.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间:" prop="createDate" style="float:right;">
            {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
          </el-form-item>
          <el-form-item label="单号:" style="float:right;">
            {{ opts.billCode || '' }}
          </el-form-item>
          <div style="clear: both;"></div>
        </el-col>
      </el-row>

      <table class="form-table">
        <tr style="height:0px;">
          <td style="width:18%;"></td>
          <td style="width:32%;"></td>
          <td style="width:18%;"></td>
          <td style="width:32%;"></td>
        </tr>
        <tr>
          <td class="form-label">收款/付款账户：</td>
          <td class="form-label form-input" style="text-align: left;">
            <el-select v-model="forms.receiptBankInfo" @change="bankChange">
              <el-option v-for="dd in dict.banks" :key="dd.value" :label="dd.label" :value="dd.value" />
            </el-select>
          </td>
          <td class="form-input form-center">所属银行：</td>
          <td class="form-input">
            <el-input v-model="forms.receiptBankName" disabled style="max-width:100%;"></el-input>
          </td>
        </tr>
        <tr>
          <td class="form-input form-center">账户名称：</td>
          <td class="form-input">
            <el-input v-model="forms.receiptCardName" disabled style="max-width:100%;"></el-input>
          </td>
          <td class="form-input form-center">账户编号：</td>
          <td class="form-input">
            <el-input v-model="forms.receiptCardNo" disabled style="max-width:100%;"></el-input>
          </td>
        </tr>
        <tr>
          <td class="form-label">收款/付款备注：</td>
          <td class="form-label form-input" colspan="3" style="text-align: left;">
            <el-input v-model="forms.purpose" name="purpose" auto-complete="on" placeholder="钱款来源备注"></el-input>
          </td>
        </tr>
        <tr>
          <td class="form-input form-center">收款/付款金额：</td>
          <td class="" style="text-align: left;">
            <el-input-number v-model="forms.subtotal" :min="-10000000" :max="10000000" placeholder="0.00"></el-input-number>
          </td>
          <td class="form-label">收款/付款日期：</td>
          <td class="form-label form-input">
            <el-date-picker v-model="forms.receiptDate" type="date" placeholder="款项到账日期" />
          </td>
        </tr>

        <tr>
          <td class="form-input form-center">金额大写：</td>
          <td class="form-input" colspan="3">
            <span style="margin-left:10px">{{ bigFeeStr || "零" }}</span>
          </td>
        </tr>
        <tr>
          <td class="form-label">原因说明：</td>
          <td class="form-label form-input" colspan="3" style="text-align: left; max-width: 350px;">
            <el-input type="textarea" v-model="forms.reasons" placeholder="收款/付款原因说明" />
          </td>
        </tr>

        <tr>
          <td class="form-input form-center">来源账户编号：</td>
          <td class="form-input">
            <el-input v-model="forms.payCardNo" placeholder="可为空" style="max-width:100%;"></el-input>
          </td>
          <td class="form-input form-center">来源账户名称：</td>
          <td class="form-input">
            <el-input v-model="forms.payCardName" placeholder="可为空" style="max-width:100%;"></el-input>
          </td>
        </tr>

        <tr>
          <td class="form-input" colspan="4">
            <el-row :gutter="20">
              <el-col :span="4" :offset="3">领导审批：</el-col>
              <el-col :span="4">部门审批：</el-col>
              <el-col :span="4">制单人：</el-col>
              <el-col :span="4">财务审批：</el-col>
              <el-col :span="4">出纳：</el-col>
            </el-row>
          </td>
        </tr>

      </table>
    </el-form>
  </div>
</template>
          
<script>
import { fetchBankList } from "@/api/fund";
export default {
  name: "payback",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'SKQR',
        formCode: 'payback',
        formName: '收支确认单',
        flowCode: 'flow-payback',
        status: 0
      },
      forms: this.formdata,
      rules: {
        // bankInfo: [
        //     { required: true, message: "请输入要报销的银行账户信息", trigger: "blur" },
        // ],
        // desc: [
        //     { required: true, message: "请输入报销的原因及备注", trigger: "blur" },
        // ],
      },
      departments: [],
      companys: [],

      dict: {
        feeTypes: [],
        platforms: [],
        banks: [],
        alias: [],
      }
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: {},
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    formCheck() {
      if (!this.forms.companyID || !this.forms.departmentID) {
        this.$message({ type: "info", message: "请选择对应的公司和部门", });
        return false;
      }

      this.forms.companyName = this.$refs.companys.getNode(this.forms.companyID).data.companyName;
      this.forms.departmentName = this.$refs.departments.getNode(this.forms.departmentID).data.departmentName;

      // if (!this.forms.feeType) {
      //   this.$message({ type: "info", message: "请填写单据的款项类型信息", });
      //   return false;
      // }

      if (!this.forms.receiptBankInfo || !this.forms.receiptBankName || !this.forms.receiptCardName || !this.forms.receiptCardNo) {
        this.$message({ type: "info", message: "请选择收款的银行信息", });
        return false;
      }
      if (!this.forms.purpose) {
        this.$message({ type: "info", message: "请选择收款的备注信息", });
        return false;
      }
      if (!this.forms.subtotal) {
        this.$message({ type: "info", message: "请填写收款的金额", });
        return false;
      }
      if (!this.forms.receiptDate) {
        this.$message({ type: "info", message: "请选择收款的日期", });
        return false;
      }

      return true;
    },
    getForms() {
      if (!this.opts.formDesc || this.startNode) {
        this.opts.formDesc = '金额:' + (this.forms.subtotal || '') + ';';
        this.opts.formDesc += '备注:' + (this.forms.purpose || '') + ';';
      }
      if (this.bossNode) this.opts.formDesc = '授权时间:'
        + this.$util.fmtDate(new Date(), "yyyy-MM-dd HH:mm") + ';'
        + this.opts.formDesc;
      return this.forms;
    },

    companyChange() {
      if (!this.forms.companyID) return;

      var that = this;
      this.$kaung.departments(this.forms.companyID).then((res) => {
        that.departments = res;
        if (this.forms.companyID == this.companyID) {
          this.forms.departmentID = this.userinfo.departmentID;
        }
      });
    },

    bankChange() {
      var cardInfos = this.dict.alias.filter(res => res.cardAlias == this.forms.receiptBankInfo);
      if (cardInfos && cardInfos.length > 0) {
        this.forms.receiptBankName = cardInfos[0].bankName;
        this.forms.receiptCardName = cardInfos[0].cardName;
        this.forms.receiptCardNo = cardInfos[0].cardNo;
      } else {
        this.forms.receiptBankName = '';
        this.forms.receiptCardName = '';
        this.forms.receiptCardNo = '';
      }
    }
  },
  created() {
    let that = this;
    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

      this.$kaung.companys().then((res) => {
      that.companys = res;
    });

    if (!this.$route.query.id) {
      this.forms.companyID = this.userinfo.companyID;
      this.companyChange();
      this.forms.departmentID = this.userinfo.departmentID;
    } else {
      this.companyChange();
    }

    this.$kaung.dictionary("workflow", "feeType").then((res) => {
      this.dict.feeTypes = res;
    });
    this.$kaung.dictionary("workflow", "platform").then((res) => {
      this.dict.platforms = res;
    });
    this.$kaung.dictionary("workflow", "banks").then((res) => {
      this.dict.banks = res;
    });
    fetchBankList().then((res) => {
      this.dict.alias = res.data;
    });
  },
  watch: {
    formdata: function (newValue, oldValue) {
      console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '')
        return true;
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50)
        return true;
      return false;
    },
    bossNode() {
      if (this.initOpts.nodeName && (this.initOpts.nodeName.indexOf('总经理') > -1 || this.initOpts.nodeName.indexOf('董事长') > -1))
        return true;
      return false;
    },
    bigFeeStr() {
      return this.$util.toBigMoney(this.forms.subtotal || 0);
    }
  }

};
</script>
          
<style scoped>
.el-form-item {
  margin-bottom: 5px;
}

.el-form--inline .el-form-item {
  margin-right: 10px;
}

:deep .el-select {
  vertical-align: initial;
}

:deep .el-input__wrapper,
:deep .el-textarea__inner,
:deep .el-select .el-input.is-focus .el-input__wrapper,
:deep .el-select:hover:not(.el-select--disabled) .el-input__wrapper {
  border: none;
  max-width: 100%;
  box-shadow: none !important;

  padding: 0px 8px;
}

:deep .el-select .el-input__inner {
  padding-top: 1px;
}

:deep .el-form-item__label {
  padding: 0;
}

:deep .el-input-number__increase,
:deep .el-input-number__decrease {
  display: none;
}

/* :deep .el-input__inner {
        text-align: left;
      } */

.workform {
  max-width: 980px;
  margin: 0px auto;
  padding: 20px 20px;
  color: #000;
  background: #fff;
}

.form-table {
  width: 100%;
}

.form-label {
  background: #fff;
  text-align: center;
  padding: 0px;
  font-size: 0.95em;
  font-weight: 500;
  vertical-align: middle;

  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-input {
  width: auto;
  line-height: 35px;
  font-size: 0.95em;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}


.form-center {
  text-align: center;
}


.child-table {
  font-size: 0.9em;
}

.child-table .form-label,
.child-table .form-input {
  line-height: 30px;
  padding: 5px;
}

.el-tabs__content {
  min-height: 80vh;
}

:deep .el-input-number.is-controls-right .el-input__wrapper {
  padding: 0;
}

:deep .el-input-number .el-input__inner {
  text-align: left;
}
</style>
          